import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  title?: string
  text?: string
}

export const SimpleText = memo(function SimpleText({ title, text }: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {text ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  background-color: #000;
`

const Wrapper = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 6rem 2rem;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.variants.base};

  @media all and (max-width: 571px) {
    margin-bottom: 1rem;
  }
`
const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.default};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 1.5rem;
`
