import styled from '@emotion/styled'
import { SEO } from 'app/components/SEO'
import { SimpleText } from 'app/components/SimpleText'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Navigation, Props as NavigationProps } from 'app/containers/Navigation'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  navigationProps?: NavigationProps
  footerProps?: FooterProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function ThankYouPageTemplate({
  pageContext,
}: PageProps<void, PageContext>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.navigationProps ? (
        <Navigation
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.navigationProps}
        />
      ) : null}
      {context.simpleTextProps ? (
        <SimpleText {...context.simpleTextProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
